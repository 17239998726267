import React, { useState, useEffect } from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Team from "../components/Sections/Team";
import Footer from "../components/Sections/Footer";

export default function Landing() {
 return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Team />
      <Footer />
    </>
  );
}
