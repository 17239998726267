import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Shattering project norms and crafting unforgettable customer journeys, I redefine success through innovation, driven by a passion for solutions and experience."
            author="Beatrice Omolo"
            title="HEAD OF PROJECTS AND CUSTOMER EXPERIENCE"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Crafting seamless and enjoyable interfaces that captivate users, ensuring ease of use and positive interactions."
            author="Sharon Wacheke"
            title="UI/UX DESIGNER"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Innovative technology strategist dedicated to advancing digital transformation, optimizing infrastructure, and leading cross-functional teams to deliver high-value tech solutions."
            author="Joseph Mbera"
            title="CHIEF TECHNICAL OFFICER"

          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Crafting seamless user experiences with innovative design, cutting-edge technology, and pixel-perfect precision."
            author="Meshack Otieno"
            title="FRONTEND DEVELOPER"

          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Driving brand success through strategic creativity, innovative campaigns, and data-driven marketing excellence"
            author="Sharon Birgen"
            title="MARKETING SPECIALIST"

          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  cursor: pointer;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
