import React, { useState }  from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
import HeaderImage from "../../assets/img/edward.jpeg";
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import { Button, Modal } from "antd";
import ContactPopup from "./ContactPopup";

export default function Header() {
  const [popupOpen, setPopupOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <Wrapper id="home" className="container flexSpaceCenter">
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font40">Digital Health Solutions Provider.</h1>
          <HeaderP className="font13 semiBold">
          At Medicinachain we provide an end-to-end, patient-centric healthcare management system and medicine marketplace, harnessing the power of AI and Blockchain technology to transform the healthcare experience for all stakeholders.
          </HeaderP>
          <BtnWrapper>
            <Button type='primary'onClick={openPopup}>Get In Touch</Button>
            <Button type='primary' onClick={handleOpenModal}>Book A Demo</Button>
          </BtnWrapper>
        </div>
      </LeftSide>
      {popupOpen && <ContactPopup userEmail="" userContact="" isOpen={popupOpen} onClose={closePopup} />}
      <Modal
        title="Schedule Online"
        open={isModalOpen}
        onCancel={handleCloseModal} 
        footer={null}
        width={{
          xs: '90%',
          sm: '80%',
          md: '70%',
          lg: '60%',
          xl: '50%',
          xxl: '40%',
        }}
        bodyStyle={{ height: "80vh", padding: "0" }} 

      >
        <iframe
          src='https://outlook.office365.com/owa/calendar/HMISDemo@medicinachain.com/bookings/'
          width="100%"
          height="100%"
          scrolling="yes"
          style={{ border: "0" }}
        ></iframe>
      </Modal>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={HeaderImage} alt="Edward Gichina" style={{zIndex: 9}} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Turning everyday challenges into innovation that transforms lives, leading with vision and driving impactful solutions.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Edward Gichina</p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px', fontSize:10}}>Founder and CEO</p>

            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  display: flex; // Makes buttons appear side by side
  gap: 10px; // Adds spacing between buttons
  flex-wrap: wrap; // Allows wrapping on small screens
  justify-content: flex-start; // Aligns buttons to the left; change to center if needed
  @media (max-width: 960px) {
    justify-content: center; // Center aligns on smaller screens
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


