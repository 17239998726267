import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/hosi.jpg";
import AddImage2 from "../../assets/img/consultant.jpg";
import AddImage3 from "../../assets/img/med.jpg";
import AddImage4 from "../../assets/img/hosi.jpg";

export default function Services() {
  return (
    <Wrapper id="services">
      <div className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Services</h1>
          </HeaderInfo>
          <ServiceBoxRow className="flex">
            <ServiceBoxWrapper>
              <ServiceBox
                icon="roller"
                title="Patient Data App"
              
                subtitle={  <>
                <p style={{color:"blue"}}>🌟 <strong>Patient Data management App</strong> 🌟</p>
                <p>Take control of your medical data while accessing healthcare and medication easily and affordably. Our platform ensures that your health records are securely stored and easily shareable with your consent, empowering you to manage your health journey with confidence and convenience.</p>
                <ul>
                  <li><strong>Secure Medical Records:</strong> Your data is tamper-proof and encrypted, ensuring maximum privacy and security.</li>
                  <li><strong>Easy Access to Healthcare:</strong> Schedule appointments, share medical history, and manage prescriptions seamlessly.</li>
                  <li><strong>Affordable Medication:</strong> Use our integrated medication marketplace to find and purchase medications at the best prices, with geo-location features to identify nearby pharmacies.</li>
                </ul>
              </>}
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="monitor"
                title="HMIS"
                subtitle={
                  <>
                  <p style={{color:"blue"}}>🏥 <strong>Hospital Management Information System</strong> 🩺</p>
                  <p>Experience the magic of flawless interaction with patients and access to appropriate information to optimize patient diagnosis and treatment. Medicinachain supports a comprehensive workflow from first contact to prescription, enhancing efficiency and patient outcomes.</p>
                  <ul>
                    <li><strong>Patient Management:</strong> Manage patient data, appointments, lab results, and treatment plans from a single dashboard.</li>
                    <li><strong>AI-Assisted Insights:</strong> Utilize AI-driven insights for better diagnosis, treatment recommendations, and medication information.</li>
                    <li><strong>Seamless Integration:</strong> Easily integrate with existing systems and streamline operations.</li>
                  </ul>
                </>
                }
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper>
              <ServiceBox
                icon="browser"
                title="Pharmacy management system"
                subtitle={
                  <>
                  <p style={{color:"blue"}}>💊 <strong>Wholesale and Retail Pharmacy management system</strong> 💊</p>
                  <p>Our marketplace will ensure hustle-free sales and automated dispensing, offloading most of your supply chain headaches. Connect with patients and healthcare providers effortlessly, ensuring your products reach those who need them most.</p>
                  <ul>
                    <li><strong>Efficient Sales:</strong> Simplify your sales process with our automated platform.</li>
                    <li><strong>Inventory Management:</strong> Keep track of stock levels and automate restocking.</li>
                    <li><strong>Direct Access to Consumers:</strong> Reach patients directly through our marketplace, ensuring timely and efficient delivery of medications.</li>
                  </ul>
                </>
                } />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
        {/* <div  style={{ padding: "50px 0" }}>
        <HeaderInfo>
            <h1 className="font40 extraBold" style={{marginLeft:160}}>Our Tech Stack</h1>
          </HeaderInfo>
        <div className="container">
          <ClientSlider />
        </div>
      </div> */}
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                {/* <h4 className="font15 semiBold">A few words about company</h4> */}
                <h2 className="font40 extraBold">Mission</h2>
                <p className="font12">
                Our mission is to revolutionize healthcare with AI and blockchain technology, 
                empowering patients and providers. We are on a mission to give back medical data control to the patients while reducing misdiagnosis and allowing easy and affordable access to medicine.
                </p>
                {/* <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0"}}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Get Started" action={() => alert("clicked")} />
                  </div>
                  <div style={{ width: "190px", marginLeft: "15px" }}>
                    <FullButton title="Contact Us" action={() => alert("clicked")} border />
                  </div>
                </ButtonsRow> */}
              </AddLeft>
              <AddRight>
                <AddRightInner>
                  <div className="flexNullCenter">
                    <AddImgWrapp1 className="flexCenter">
                      <img src={AddImage1} alt="office" />
                    </AddImgWrapp1>
                    <AddImgWrapp2>
                      <img src={AddImage2} alt="office" />
                    </AddImgWrapp2>
                  </div>
                  <div className="flexNullCenter">
                    <AddImgWrapp3>
                      <img src={AddImage3} alt="office" />
                    </AddImgWrapp3>
                    <AddImgWrapp4>
                      <img src={AddImage4} alt="office" />
                    </AddImgWrapp4>
                  </div>
                </AddRightInner>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 70%;
  margin-right: 15%;
  padding: 80px 0;
  left:1800px;
  @media (max-width: 960px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;