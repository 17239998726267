import React from "react";
import styled from "styled-components";
import mobile from "../../assets/img/mobile.jpg"
import hmis from "../../assets/img/web.jpg"
import pharmacy from "../../assets/img/pharma.jpg"


export default function ServiceBox({icon, subtitle}) {
  let getIcon;

  switch (icon) {
    case "roller":
      getIcon = <IconImage src={mobile} alt="Mobile Icon" />;
      break;
    case "monitor":
      getIcon = <IconImage src={hmis} alt="Mobile Icon" />;
      break;
    case "browser":
      getIcon = <IconImage src={pharmacy} alt="Mobile Icon" />;
      break;
    default:
      getIcon = <IconImage src={mobile} alt="Mobile Icon" />;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle style={{marginLeft:50}}>{getIcon}</IconStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 300px;
`;
const IconStyle = styled.div`
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const SubtitleStyle = styled.p`
  width: 120%;
  max-width: 300px;
  margin: 0 auto;
`;
const IconImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 200px;
  border-radius: 10px;
`;